@tailwind base;
@tailwind components;
@tailwind utilities;


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
	font-family: "Altone Trial-Bold";
	font-style: normal;
	font-weight: 700;
	src: url('https://anima-uploads.s3.amazonaws.com/projects/65cc6d95945a157a889d7aeb/fonts/altone-trial-bold.ttf') format("truetype");
}

@font-face {
	font-family: "Altone Trial-Regular";
	font-style: normal;
	font-weight: 400;
	src: url('https://anima-uploads.s3.amazonaws.com/projects/65cc6d95945a157a889d7aeb/fonts/altone-trial-regular.ttf') format("truetype");
}

.helvetia {
	font-family: "Poppins", serif;
}

body {
	margin: 0;
	font-family: 'Altone Trial-Regular', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.btn {
	border-radius: 10px;
	background: rgb(29, 29, 227);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	color: white;
	transition: .2s ease-in-out;
	white-space: nowrap;
	font-weight: 700;
	width: fit-content;

	&:hover {
		background-color: white;
		color: #1D1DE3;
	}
}

.bluebg {
	background-color: #1D1DE3;
}

.header {
	background-image: url("./assets/mainbg.jpg");
	background-position: 0 -13px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

h1 {
	font-weight: 700;
	line-height: 76px;
}

h2 {
	font-weight: 700;
	line-height: 50px;
}

h3 {
	font-weight: 700;
	line-height: 29px;
}

@media (min-width: 1536px) {
	.container {
		max-width: 1200px;
	}
}

@media (min-width: 1280px) {

	.container {
		max-width: 1200px;
	}
}

.faq-body {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.faq-row {
	border: none !important;
	background-color: #1D1DE3;
	border-radius: 16px;
	padding: 15px 30px !important;
}

.row-title-text {
	color: white;
	font-size: 24px;
	font-weight: 700;
}

.row-content-text {
	color: white !important;
	font-size: 20px !important;
}

.icon-wrapper {
	font-size: 20px;
	color: white !important;
	top: 26px !important;
	right: 50px !important;
}

@media (max-width: 560px) {
	.faq-row {
		border: none !important;
		background-color: #1D1DE3;
		border-radius: 16px;
		padding: 12px 24px !important;
	}

	.row-title-text {
		color: white;
		font-size: 20px;
		font-weight: 700;
	}

	.row-content-text {
		color: white !important;
	}

	.icon-wrapper {
		font-size: 18px;
		color: white !important;
		top: 26px !important;
		right: 25px !important;
	}
}


.swiper-slide {
	background-repeat: no-repeat;
	background-size: contain;
	max-height: 530px;
	aspect-ratio: 16/9;
	// height: 100%!important;
}

@media (max-width: 1280px) {
	.swiper-slide {
		max-height: 450px;
	}
}

@media (max-width: 1020px) {
	.swiper-slide {
		max-height: 335px;
	}
}

@media (max-width: 768px) {
	.swiper-slide {
		max-height: 278px;
	}
}


@media (max-width: 650px) {
	.swiper-slide {
		aspect-ratio: 16/7;
	}
}

@media (max-width: 560px) {
	.swiper-slide {
		max-height: auto;
	}
}

.ride {
	border: 2px solid #1D1DE3;
	color: rgb(147, 147, 193);

	.tabs {
		.tab {
			border-bottom: 3px solid rgb(197, 197, 255);

			&:nth-child(1) {
				justify-content: start;
				padding-left: 90px;
			}

			&:last-child {
				justify-content: end;
				padding-right: 90px;
			}
		}

		.tab_active {
			color: #1D1DE3;
			border-bottom: 3px solid #1D1DE3;
		}
	}
}

@media (max-width: 1024px) {
	.ride {
		.tabs {
			.tab {

				&:nth-child(1) {
					padding-left: 40px;
				}

				&:last-child {
					padding-right: 40px;
				}
			}
		}
	}
}

@media (max-width: 640px) {
	.ride {
		.tabs {
			.tab {

				&:nth-child(1) {
					padding-left: 0px;
					justify-content: center;
				}

				&:last-child {
					padding-right: 0px;
					justify-content: center;
				}
			}
		}
	}
}

.colorblue {
	color: #1D1DE3;
}

.datepicker-picker {
	font-family: "Poppins", serif;
}

/* For most modern browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.counter-input {
	border-radius: 8px !important;
	@apply helvetia bg-gray-50 border-0 text-blue-500 focus:outline-offset-0 focus:!outline-2 focus:outline-blue-500 focus:border-transparent text-base block w-full p-2;
	box-shadow: 0 0 0 1px #1D1DE3;
}

.input {
	@apply bg-gray-50 border border-blue-400 text-blue-500 placeholder:text-blue-500 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5;
	font-size: 16px;
}

.datepicker {
	@apply h-full;

	div.pointer-events-none.absolute.inset-y-0.left-0.flex.items-center.pl-3 {
		display: none;
	}

	input {
		@apply bg-gray-50 border border-blue-400 text-blue-500 placeholder:text-blue-500 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5;
		font-size: 16px;
		height: 46px;
	}
}

input::-webkit-date-and-time-value {
	text-align: left;
}